import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ticket from "../../images/Group 91.png";
import formulario from "../../styles/FormularioTD.module.css";
import { useDataContext } from "../../contexts/AuthContext";
import tickettb from "../../images/comprobantes ui-debito.png";
import { useNavigate } from "react-router-dom";
import { getPaymentInformation } from "../../services/api";
import { paymentTD } from "../../services/api";
import fantasmaImg from "../../images/fantasma-opinion.png";
import logoPng from "../../images/Frame.png";
import Swal from "sweetalert2";

export default function TarjetaDeDebito({ toggleModal3 }) {
  const navigate = useNavigate();
  const { contextData } = useDataContext();

  // const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [nombre, setNombre] = useState("");
  const [digitos, setDigitos] = useState("666");
  const [showDigitsInput, setShowDigitsInput] = useState(false);
  const [purchaseId, setPurchaseId] = useState("");
  const [amount, setAmount] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [showImage, setShowImage] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [singlePayment, setSinglePayment] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(0);
  const [amountError, setAmountError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!contextData || !contextData.customer || !contextData.customer.id) {
      return;
    }

    const fetchPaymentInformation = async () => {
      try {
        const data = await getPaymentInformation(contextData.customer.id);
        console.log("Datos de pago:", data);
        setPaymentMethod(data.payment_method_number);
        setPaymentType(data.payment_type);
        setLoading(false);
      } catch (error) {
        console.log(
          `Error en la solicitud de información de pago: ${error.message}`
        );
      }
    };

    fetchPaymentInformation();
  }, [contextData.customer.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (amount <= 100) {
        setAmountError("El monto debe ser mayor que 100");
        return;
      } else {
        setAmountError("");
      }

      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);

      const formData = new FormData();
      formData.append("selectedDate", selectedDate);
      formData.append("amount", amount);
      formData.append("nombre", nombre);
      formData.append("digitos", digitos);
      formData.append("bapurchaseIdnco", purchaseId);
      formData.append("paymentMethod", paymentMethod);
      formData.append("paymentType", paymentType);
      formData.append("singlePayment", singlePayment);
      formData.append("paymentStatus", paymentStatus);
      formData.append("archivoAdjunto", file);
      formData.append("customer", contextData.customer.id);
      formData.append("order_id", contextData.customer.order_id);

      const response = await paymentTD(formData, contextData.customer.id);

      if (response.ok) {
        const nuevoPayment = await response.json();
        console.log("Payment creado exitosamente:", nuevoPayment);
        navigate("/correcto");
      } else {
        const errorData = await response.json();
        console.error(
          "Error al crear el pago:",
          response.status,
          response.statusText,
          errorData
        );
        Swal.fire({
          icon: "error",
          iconColor: "#d54ab7",
          confirmButtonColor: "#0071ce",
          title: "Error al crear el pago",
          text: "Por favor, inténtelo de nuevo más tarde.",
        });
      }
    } catch (error) {
      console.error("Error al crear el pago:", error);
      Swal.fire({
        icon: "error",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Error al crear el pago",
        text: "Por favor, inténtelo de nuevo más tarde.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileInputChange = (e) => {
    e.preventDefault();
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    console.log(selectedFile);
  };

  if (loading) {
    return (
      <div className="contcargando">
        <img src={logoPng} alt="littleBlueLogo" className="imgcargando" />
        <h2 className="text-muted">CARGANDO...</h2>
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <div className={formulario.deskcontent}>
        <div
          className={`container-fluid d-flex align-items-center justify-content-center  ${formulario.contenedorprincipalform}`}
        >
          <div className={formulario.conteinerprincipal}>
            <div className={`${formulario.containerTitulo}`}>
              <h2 className={`text-center ${formulario.titulotd}`}>
                TARJ. DÉBITO
              </h2>
              <p className={`${formulario.mesegetd}`}>
                Por favor completá los datos que te marcamos en los suiguientes
                campos.
              </p>
            </div>
            <form
              onSubmit={handleSubmit}
              className={`d-flex flex-wrap justify-content-between ${formulario.contenedorformtd}`}
            >
              <div className="mb-3">
                <img
                  src={tickettb}
                  alt="Descripción de la imagen"
                  className={`${formulario.imagetd}`}
                />
              </div>
              <div className={`w-48 ${formulario.containertd}`}>
                <div className="form-group mb-3">
                  <label className={formulario.label}>Fecha de pago</label>
                  <DatePicker
                    placeholderText="fecha del comprobante"
                    className={`form-control ${formulario.inputfecha}`}
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    required
                  />
                </div>

                <div
                  className="form-group mb-3"
                  style={{ display: showDigitsInput ? "block" : "none" }}
                >
                  <label className={formulario.label}>
                    Ingresá los ultimos 4 dígitos de la tarjeta
                  </label>
                  <input
                    className={`form-control ${formulario.input}`}
                    type="text"
                    value={digitos}
                    onChange={(e) => setDigitos(e.target.value)}
                    pattern="[0-9]*"
                  />
                  {digitos && !/^\d+$/.test(digitos) && (
                    <div className="text-danger">Ingresa solo números</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className={formulario.label}>ID de Compra:</label>
                  <input
                    className={`form-control ${formulario.input}`}
                    type="text"
                    value={purchaseId}
                    onChange={(e) => setPurchaseId(e.target.value)}
                    pattern="[0-9]*"
                    required
                  />
                  {purchaseId && !/^\d+$/.test(purchaseId) && (
                    <div className={`text-danger ${formulario.valitf}`}>
                      Ingresa solo números
                    </div>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className={formulario.label}>IMPORTE</label>
                  <input
                    className={`form-control ${formulario.input}`}
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    pattern="[0-9]*"
                    required
                  />
                  {amount && !/^\d+$/.test(amount) && (
                    <div className={`text-danger ${formulario.valitf}`}>
                      Ingresa solo números
                    </div>
                  )}
                  {amountError && (
                    <div className={`text-danger ${formulario.valitf}`}>
                      {amountError}
                    </div>
                  )}
                </div>

                <div className="w-48">
                  <div
                    className={`form-group mb-3 text-center ${
                      formulario.contenedorimagentd
                    } ${file ? "has-comprobante" : ""}`}
                  >
                    <div className={formulario.containerAjuntar}>
                      <img
                        src={ticket}
                        alt="Descripción de la imagen"
                        className={`${formulario.fototicket}`}
                      />

                      <div className="form-group mb-3 text-center">
                        <input
                          className="form-control-file"
                          type="file"
                          accept=".pdf, .jpg, .png"
                          style={{ display: "none" }}
                          onChange={handleFileInputChange}
                          ref={fileInputRef}
                        />
                        <button
                          className={`btn btn-primary btn-block ${formulario.botonadjuntartd}`}
                          onClick={(e) => {
                            e.preventDefault();
                            fileInputRef.current.click();
                          }}
                        >
                          {file
                            ? `Adjuntar Otro Comprobante`
                            : "Adjuntar Comprobante"}
                        </button>
                        {!file && (
                          <img
                            src={fantasmaImg}
                            alt="Fantasma"
                            className={formulario.fantasmaImg}
                          />
                        )}
                      </div>
                    </div>
                    {file && (
                      <div className="mb-3 text-center">
                        <img
                          src={URL.createObjectURL(file)}
                          alt="Comprobante adjunto"
                          className={formulario.imagenchicatd}
                        />
                      </div>
                    )}
                    <div className={formulario.mensajefecha}>
                      <p className={formulario.mensajefecha2}>
                        Verifique que la fecha sea visible
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`w-100 mt-3 ${formulario.containerbotontd}`}>
                <button
                  type="submit"
                  className={`btn btn-primary btn-block ${formulario.botonenviartd}`}
                  disabled={!file || isSubmitting}
                >
                  {isSubmitting ? "Enviando..." : "Enviar"}
                </button>
                <button
                  type="button"
                  className={`btn btn-secondary ${formulario.botoncerrartd}`}
                  onClick={toggleModal3}
                >
                  Cerrar
                </button>
                {!file && (
                  <div className={`text-danger mt-2 ${formulario.mensajetd}`}>
                    Debes cargar el comprobante
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* celular */}
      <div className={formulario.celcontent}>
        <div
          className={`container-fluid d-flex align-items-center justify-content-center  ${formulario.contenedorprincipalform}`}
        >
          <div className={formulario.conteinerprincipal}>
            <div className={`${formulario.containerTitulo}`}>
              <h2 className={`text-center ${formulario.titulotd}`}>
                TARJ. DÉBITO
              </h2>
              <p className={`${formulario.mesegetd}`}>
                Por favor completá los datos que te marcamos en los suiguientes
                campos.
              </p>
            </div>
            <form
              onSubmit={handleSubmit}
              className={`d-flex flex-wrap  ${formulario.contenedorformtd}`}
            >
              {/* <div className="mb-3">
                <img
                  src={tickettb}
                  alt="Descripción de la imagen"
                  className={`${formulario.imagetd}`}
                />
              </div> */}
              <div className={`w-48 ${formulario.containertd}`}>
                <div className="form-group mb-3">
                  <label className={formulario.label}>Fecha de pago</label>
                  <DatePicker
                    placeholderText="fecha del comprobante"
                    className={`form-control ${formulario.inputfecha}`}
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd/MM/yyyy"
                    required
                  />
                </div>

                <div
                  className="form-group mb-3"
                  style={{ display: showDigitsInput ? "block" : "none" }}
                >
                  <label className={formulario.label}>
                    Ingresá los ultimos 4 dígitos de la tarjeta
                  </label>
                  <input
                    className={`form-control ${formulario.input}`}
                    type="text"
                    value={digitos}
                    onChange={(e) => setDigitos(e.target.value)}
                    pattern="[0-9]*"
                  />
                  {digitos && !/^\d+$/.test(digitos) && (
                    <div className="text-danger">Ingresa solo números</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className={formulario.label}>
                    ID de Compra:
                    <button
                      type="button"
                      className={`btn btn-link ${formulario.btnss}`}
                      onClick={() => setShowImage(true)}
                    >
                      <i className="bi bi-question-circle"></i>
                    </button>{" "}
                  </label>
                  <input
                    className={`form-control ${formulario.input}`}
                    type="text"
                    value={purchaseId}
                    onChange={(e) => setPurchaseId(e.target.value)}
                    pattern="[0-9]*"
                    required
                  />
                  {purchaseId && !/^\d+$/.test(purchaseId) && (
                    <div className={`text-danger ${formulario.valitf}`}>
                      Ingresa solo números
                    </div>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className={formulario.label}>IMPORTE</label>
                  <input
                    className={`form-control ${formulario.input}`}
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    pattern="[0-9]*"
                    required
                  />
                  {amount && !/^\d+$/.test(amount) && (
                    <div className={`text-danger ${formulario.valitf}`}>
                      Ingresa solo números
                    </div>
                  )}
                  {amountError && (
                    <div className={`text-danger ${formulario.valitf}`}>
                      {amountError}
                    </div>
                  )}
                </div>
              </div>

              <div className="w-48">
                <div
                  className={`form-group mb-3 text-center ${
                    formulario.contenedorimagentd
                  } ${file ? "has-comprobante" : ""}`}
                >
                  <div className={formulario.containerAjuntar}>
                    {/* <img
                        src={ticket}
                        alt="Descripción de la imagen"
                        className={`${formulario.fototicket}`}
                      /> */}

                    <div className={formulario.celcontent2}>
                      <input
                        className="form-control-file"
                        type="file"
                        accept=".pdf, .jpg, .png"
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                        ref={fileInputRef}
                      />
                      <button
                        className={`btn btn-primary btn-block ${formulario.botonadjuntartd}`}
                        onClick={(e) => {
                          e.preventDefault();
                          fileInputRef.current.click();
                        }}
                      >
                        {file
                          ? `Adjuntar Otro Comprobante`
                          : "Adjuntar Comprobante"}
                      </button>
                      {!file && (
                        <img
                          src={fantasmaImg}
                          alt="Fantasma"
                          className={formulario.fantasmaImg}
                        />
                      )}
                    </div>
                  </div>
                  {file && (
                    <div className="mb-3 text-center">
                      <img
                        src={URL.createObjectURL(file)}
                        alt="Comprobante adjunto"
                        className={formulario.imagenchicatd}
                      />
                    </div>
                  )}
                </div>
                <div className={formulario.mensajefecha}>
                  <p className={formulario.mensajefecha2}>
                    Verifique que la fecha sea visible
                  </p>
                </div>
              </div>

              <div className={`w-100 mt-3 ${formulario.containerbotontd}`}>
                <button
                  type="submit"
                  className={`btn btn-primary btn-block ${formulario.botonenviartd}`}
                  disabled={!file || isSubmitting}
                >
                  {isSubmitting ? "Enviando..." : "Enviar"}
                </button>
                <button
                  type="button"
                  className={`btn btn-secondary ${formulario.botoncerrartd}`}
                  onClick={toggleModal3}
                >
                  Cerrar
                </button>
                {!file && (
                  <div className={`text-danger mt-2 ${formulario.mensajetd}`}>
                    Debes cargar el comprobante
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {showImage && (
        <div
          className={`modal fade ${showImage ? "show" : ""}`}
          id="imageModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: showImage ? "block" : "none" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowImage(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className={`modal-body ${formulario.containermodal}`}>
                <img
                  src={tickettb}
                  alt="Comprobante adjunto"
                  className={formulario.imagenmodal}
                />
              </div>
            </div>
          </div>
        </div>
      )}{" "}
    </>
  );
}
